import * as React from 'react';
import { graphql } from 'gatsby';
import MainLayout from '../layouts/main'; 
import Seo from '../components/seo';
import FeaturedArticles from '../components/widgets/featuredArticle';
import { ArticleWrapper } from '../components/article/styled';
import './../scss/custom.scss';

const Page = ({ data, pageContext }) => {

  const { edges } = data.allMdx;
  const { tag } = pageContext;
  const title = `All articles about "${tag}"`;
  const description = `Personal blog posts about ${tag}`;
  return (
    <MainLayout>
      <Seo title={title} description={description} />
      <ArticleWrapper>
        <h1 className="title">{title}</h1>
        <FeaturedArticles articles={edges} />
      </ArticleWrapper>
    </MainLayout>
  );
};

export default Page;

export const query = graphql`
   query ($tag: String) {
      allMdx(filter: {frontmatter: {tags: {eq: $tag}}}) {
        edges {
          node {
            frontmatter {
              title
              date(formatString: "MMMM Do, YYYY")
              description
              tags
              category
              author
              hero_image {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
            slug
          }
        }
      }
   }
`;
